import React, {createContext, useContext, useEffect, useState} from "react";
import Api from '../../helpers/Api';
import LegalfileHelper, {CompleteLegalFile} from '../../helpers/LegalfileHelper';

interface LegalFileProviderProps {
    children: React.ReactNode
}

export const LegalFileContext = createContext<any>(null); //TODO: don't use any. context is using some complex logic here and is throwing errors when giving a specific type.

export const LegalFileProvider: React.FC<LegalFileProviderProps> = ({children }) => {
    const [legalFile, setLegalFile] = useState<CompleteLegalFile | null>(null);
//    const lfHelper = new LegalfileHelper();

//    useEffect(() => {
//        //Update LegalFile in DB whenever legalfile state is changed.
//        if(legalFile){
//            saveLegalFile(legalFile)
//        }
//
//    }, [legalFile])
//
//    const saveLegalFile = async (lf: CompleteLegalFile) => {
////        await lf.save();
//
//        console.log(lf.getLegalFile('individual'))
//    }

    return (
        <LegalFileContext.Provider value={[legalFile, setLegalFile]}>
            {children}
        </LegalFileContext.Provider>
    );
};
