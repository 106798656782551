import {useContext, useEffect, useState} from "react";
import {EstatePlanSummaryContext} from "../global/EstatePlanSummaryProvider";
import Definitions from "../../definitions";
import {getEstatePlanSummary} from "../../helpers/EstatePlanHelper";
import {useDetermineAccess} from "./CustomHooks/useDetermineAccess";

export default function useSortPackageByOwner() {
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [estatePlanSummary, setEstatePlanSummary] = useContext(EstatePlanSummaryContext);
    const [packages, setPackages] = useState({});
    const { isStakeholder } = useDetermineAccess();

    useEffect(() => {
        const getEPSummary = async () => {
            const localEstatePlanSummary = await getEstatePlanSummary(estatePlanSummary.id);
            await setEstatePlanSummary(localEstatePlanSummary);
        }

        void getEPSummary();
        setFirstRender(false);
    }, []);

    useEffect(() => {
        if (!firstRender) {
            sortPackagesByOwner();
        }
    }, [estatePlanSummary]);

    function sortPackagesByOwner() {
        let primaryPackages: any[] = [];
        let secondaryPackages: any[] = [];
        let jointPackages: any[] = [];
        estatePlanSummary?.packages?.forEach((thePackage: any) => {
            // exclude assets and deeds for non stakeholders
            if ((isStakeholder === false) && [Definitions.packageList.AssetSchedules, Definitions.packageList.AssetSchedulesSeparate, Definitions.packageList.Deed].includes(thePackage.alias)) {
                return;
            }
            else {
                thePackage.status.forEach( (status:any) => {
                    const newPackage = {...thePackage, status: [status]};
                    newPackage.packageVersions = status.packageVersions;
                    newPackage.expectedPrice = status.price;
                    if (determineIfPackageNeedsRevision(newPackage, status.ownerId)) {
                        newPackage.needsRevision = true;
                    }
                    if (status.ownerId && status.ownerId === estatePlanSummary.ownerIdPrimary) {
                        primaryPackages.push({...newPackage, ownerId: estatePlanSummary.ownerIdPrimary})
                    }
                    else if (status.ownerId && status.ownerId === estatePlanSummary.ownerIdSecondary) {
                        secondaryPackages.push({...newPackage, ownerId: estatePlanSummary.ownerIdSecondary})
                    }
                    else if (status.ownerId === null) {
                        jointPackages.push({...newPackage, ownerId: null})
                    }
                })
            }
        })

        setPackages({
            primary: sortPackagesToOrder(primaryPackages),
            secondary: sortPackagesToOrder(secondaryPackages),
            joint: sortPackagesToOrder(jointPackages),
            all: [...sortPackagesToOrder(primaryPackages), ...sortPackagesToOrder(secondaryPackages), ...sortPackagesToOrder(jointPackages)] // unsorted intentionally
        });
    }

    function sortPackagesToOrder(packages: any[]) {
        return packages.sort((a: any, b: any) => {
            const indexA = packageSortOrder.indexOf(a.alias);
            const indexB = packageSortOrder.indexOf(b.alias);

            const defaultIndex = estatePlanSummary.packages.length;
            const sortedIndexA = indexA !== -1 ? indexA : defaultIndex;
            const sortedIndexB = indexB !== -1 ? indexB : defaultIndex;

            return sortedIndexA - sortedIndexB;
        })
    }

    function determineIfPackageNeedsRevision(pkg: any, ownerId: number | null) {
        const guideSummary = estatePlanSummary?.settings?.guideSummary;
        if (!guideSummary) {
            return false;
        }

        if (guideSummary['trustChanges'] && Definitions.trustTypes.includes(pkg.alias) && (ownerId === estatePlanSummary.ownerIdPrimary || !ownerId)) {
            return true;
        } else if (guideSummary['secondaryTrustChanges'] && Definitions.trustTypes.includes(pkg.alias) && ownerId === estatePlanSummary.ownerIdSecondary) {
            return true;
        }   else if (guideSummary['willChanges'] && [Definitions.estatePlanTypes.SimpleWill, Definitions.estatePlanTypes.ConventionalWill, Definitions.estatePlanTypes.CanadaWill].includes(pkg.alias) && ownerId === estatePlanSummary.ownerIdPrimary) {
            return true;
        }   else if (guideSummary['secondaryWillChanges'] && [Definitions.estatePlanTypes.SimpleWill, Definitions.estatePlanTypes.ConventionalWill, Definitions.estatePlanTypes.CanadaWill].includes(pkg.alias) && ownerId === estatePlanSummary.ownerIdSecondary) {
            return true;
        }   else if (guideSummary['primaryFPOAChanges'] && pkg.alias === Definitions.packageList.FinancialPOA && ownerId === estatePlanSummary.ownerIdPrimary) {
            return true;
        }   else if (guideSummary['secondaryFPOAChanges'] && pkg.alias === Definitions.packageList.FinancialPOA && ownerId === estatePlanSummary.ownerIdSecondary) {
            return true;
        }   else if (guideSummary['primaryHPOAChanges'] && pkg.alias === Definitions.packageList.HealthCarePOA && ownerId === estatePlanSummary.ownerIdPrimary) {
            return true;
        }   else if (guideSummary['secondaryHPOAChanges'] && pkg.alias === Definitions.packageList.HealthCarePOA && ownerId === estatePlanSummary.ownerIdSecondary) {
            return true;
        }
        return false;
    }

    const packageSortOrder = [
        Definitions.packageList.FinancialPOA,
        Definitions.packageList.HealthCarePOA,
        Definitions.packageList.MarriedJointTrust,
        Definitions.packageList.MarriedJointTrustAB,
        Definitions.packageList.SinglePersonTrust,
        Definitions.packageList.ConventionalWill,
        Definitions.packageList.AssetSchedules,
        Definitions.estatePlanTypes.None,
        Definitions.packageList.ContinuingPOA,
        Definitions.packageList.POAPersonalCare,
        Definitions.packageList.Deed,
    ]

    return packages;
}