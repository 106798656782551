import React from 'react';
import Button, {ButtonSize, ButtonType} from "../buttons/Button";


const NotFound = () => {
    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <div className={"flex-col text-center pt-[32px] flex gap-[48px] font-header items-center justify-center"}>
            <div>
                <p className={"text-neutralSecondary text-[24px] font-bold"}>
                    404 Not Found
                </p>
                <p className={"text-primary text-[48px] pt-[16px] font-bold"}>
                    Hmmm... this is unexpected
                </p>
            </div>
            <p className={"text-neutralSecondary text-[30px] font-bold"}>
                There was an issue retrieving your page, <br/> we are working hard to resolve this issue.
            </p>
            <p className={"text-primary text-[24px] font-bold"}>
                Please try clicking the back button in your <br /> browser or one of the buttons below.
            </p>
            <div className={"flex"}>
                <div className={"pr-[32px]"}>
                    <Button
                        buttonType={ButtonType.Secondary}
                        onClick={reloadPage}
                        buttonSize={ButtonSize.Large}
                    >
                        Reload page
                    </Button>
                </div>
                <div>
                    <Button
                        forNavigation={true}
                        navigateTo={'/app/client'}
                        buttonSize={ButtonSize.Large}
                    >
                        Return to dashboard
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
