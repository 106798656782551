import {Link, useLocation} from "react-router-dom";
import Button, {ButtonSize, ButtonType} from "../../global/buttons/Button";
import Divider from "../../global/Divider";
import React, {useContext, useEffect, useState} from "react";
import {useDetermineAccess} from "../CustomHooks/useDetermineAccess";
import {UserContext} from "../../global/UserProvider";
import EG from "../../../helpers/EG";

const profileNavigation = [
    { name: 'myProfile', href: '', label: "My Profile", hideFromStakeholder: false, hideFromAdvisor: false, },
    { name: 'documentHistory', href: 'document-history', label: "Document History", hideFromStakeholder: true, hideFromAdvisor: false, },
    { name: 'accountPassword', href:  'account-password', label: "Account Password", hideFromStakeholder: false, hideFromAdvisor: true, },
    { name: 'security', href: 'account-security', label: "Security", hideFromStakeholder: false, hideFromAdvisor: true, },
]

const getTabNameFromUrlPath = (navigationMap: any, lastSegment: string) => {
    const matchingTab = navigationMap.find((tab: any) => tab.href === lastSegment);
    return matchingTab ? matchingTab.name : 'myProfile';
};

export default function Profile () {
    const [user] = useContext(UserContext);
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];

    const {isAdmin, isAdvisor, isStakeholder} = useDetermineAccess();

    const [navigationMap] = useState<any[]>(profileNavigation);
    const [selectedTab, setSelectedTab] = useState(getTabNameFromUrlPath(navigationMap, lastSegment));

    useEffect(() => {
        setSelectedTab(getTabNameFromUrlPath(navigationMap, lastSegment));
    }, [lastSegment]);

    return (
        <div className={"flex-col w-full space-y-6"}>
            <Divider />
            <div className={"w-full flex justify-center items-center"}>
                <Button
                    className={"flex"}
                    arrow={"left"}
                    buttonType={ButtonType.Secondary}
                    forNavigation={true}
                    navigateTo={'/app/client'}
                    buttonSize={ButtonSize.ExtraSmall}
                >
                    Close
                </Button>
            </div>
            <Divider />
            <div className={"flex flex-col w-full text-neutralSecondary text-sm leading-[21px]"}>
                {
                    profileNavigation.map((link: any, linkIndex: number) => {
                        if (!isAdmin && (
                            (link.hideFromStakeholder && isStakeholder) ||
                            (link.hideFromAdvisor && isAdvisor)
                        )) {
                            return <></>;
                        }

                        return (
                            <Link
                                to={link.href}
                                key={linkIndex}
                                className={`${selectedTab === link.name ? "bg-secondary-300 border-secondary border-r-[3px] mr-[1px] font-semibold" : ""} hover:bg-secondary-300 cursor-pointer py-2 px-6`}
                            >
                                {link.label}
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    );
}
