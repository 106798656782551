// AlertProvider.tsx
import React, { useState, ReactNode, useEffect } from 'react';
import { Modal, ModalSize } from './Modal';
import Alert from '../Alert';
import { setShowAlertFunction, setShowModalFunction, setHideModalFunction, setHideAlertFunction } from '../../../helpers/AlertUtils';

export interface AlertOptions {
    infoText: string;
    description?: string;
    alertIcon?: boolean;
    xIcon?: boolean;
    label?: string;
    color?: "blue" | "white" | "green" | "red" | "orange";
    onXClick?: () => void;
    size?: "xsm" | "sm" | "md" | "lg" | "default";
    marginTop?: string;
    autoHide?: boolean;
}

export interface ModalOptions {
    message: string | JSX.Element;
    title?: string | JSX.Element;
    subtitle?: string | JSX.Element;
    text?: string | JSX.Element;
    onClose?: () => void;
    onPrimaryButtonClick?: () => void;
    onSecondaryButtonClick?: () => void;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    size?: ModalSize;
    checkboxText?: string;
    warningIcon?: boolean;
    successIcon?: boolean;
    checkBoxClick?: (val: boolean) => void;
    disableOutsideClick?: boolean;
    showXClose?: boolean;
}

const AlertProvider = ({ children }: { children: ReactNode }) => {
    const [alertOptions, setAlertOptions] = useState<AlertOptions | ModalOptions | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isModal, setIsModal] = useState(false);

    useEffect(() => {
        setShowModalFunction(showModal);
        setShowAlertFunction(showAlert);
        setHideModalFunction(hideModal);
        setHideAlertFunction(hideAlert);
    }, []);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if ((alertOptions as AlertOptions)?.autoHide) {
            timer = setTimeout(() => {
                handleClose();
            }, 5000); // 5 seconds
        }
        return () => {
            if (timer) clearTimeout(timer); // Clear timeout if component unmounts
        };
    }, [alertOptions]);

    const showAlert = (options: AlertOptions) => {
        setAlertOptions(options);
        setIsModal(false);
        setIsOpen(true);
    };

    const showModal = (options: ModalOptions) => {
        setAlertOptions(options);
        setIsModal(true);
        setIsOpen(true);
    };

    const hideAlert = () => {
        if (!isModal) {
            handleClose();
        }
    };

    const hideModal = () => {
        if (isModal) {
            handleClose();
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        if (isModal && (alertOptions as ModalOptions)?.onClose) {
            (alertOptions as ModalOptions).onClose!();
        } else if (!isModal && (alertOptions as AlertOptions)?.onXClick) {
            (alertOptions as AlertOptions).onXClick!();
        }
        setAlertOptions(null);
    };

    const handlePrimaryButtonClick = () => {
        if ((alertOptions as ModalOptions)?.onPrimaryButtonClick) {
            (alertOptions as ModalOptions).onPrimaryButtonClick!();
        }
        handleClose();
    };

    const handleSecondaryButtonClick = () => {
        if ((alertOptions as ModalOptions)?.onSecondaryButtonClick) {
            (alertOptions as ModalOptions).onSecondaryButtonClick!();
        }
        handleClose();
    };

    return (
        <>
            {children}
            {alertOptions && !isModal && (
                <div className={`fixed top-0 inset-x-0 flex items-center justify-center z-50 ${(alertOptions as AlertOptions).marginTop || "mt-headerHeight"}`}>
                    <div className="w-1/3 mx-auto mt-4 min-w-[200px]">
                        <Alert
                            size={(alertOptions as AlertOptions).size}
                            infoText={(alertOptions as AlertOptions).infoText}
                            description={(alertOptions as AlertOptions).description}
                            alertIcon={(alertOptions as AlertOptions).alertIcon}
                            xIcon={(alertOptions as AlertOptions).xIcon}
                            color={(alertOptions as AlertOptions).color || "red"}
                            onXClick={handleClose}
                        />
                    </div>
                </div>
            )}
            {alertOptions && isModal && (
                <Modal
                    show={isOpen}
                    title={(alertOptions as ModalOptions).title || undefined}
                    subtitle={(alertOptions as ModalOptions).subtitle}
                    text={(alertOptions as ModalOptions).text || (alertOptions as ModalOptions).message}
                    onClose={handleClose}
                    onPrimaryButtonClick={handlePrimaryButtonClick}
                    onSecondaryButtonClick={handleSecondaryButtonClick}
                    primaryButtonText={(alertOptions as ModalOptions).primaryButtonText || "Close"}
                    secondaryButtonText={(alertOptions as ModalOptions).secondaryButtonText}
                    size={(alertOptions as ModalOptions).size || ModalSize.Medium}
                    checkboxText={(alertOptions as ModalOptions).checkboxText}
                    setIsOpen={setIsOpen}
                    warningIcon={(alertOptions as ModalOptions).warningIcon}
                    successIcon={(alertOptions as ModalOptions).successIcon}
                    checkBoxClick={(alertOptions as ModalOptions).checkBoxClick}
                    disableOutsideClick={(alertOptions as ModalOptions).disableOutsideClick || false}
                    showXClose={(alertOptions as ModalOptions).showXClose || true}
                />
            )}
        </>
    );
};

export default AlertProvider;