import {useEffect, useState} from "react";
import Api from "../../../helpers/Api";
import EG from "../../../helpers/EG";

export default function useGetAttorneyStates(): string[] | null {
    const [attorneyStates, setAttorneyStates] = useState<string[] | null>(null);

    useEffect(() => {
        void getAttorneyStates();
    }, []);

    const getAttorneyStates = async () => {
        const response = await Api.get(`whitelabel/${EG.getWhiteLabelId()}/attorneystates`);
        if (response?.status === 200) {
            const data = await response.json();
            setAttorneyStates(data);
        }
    }

    return attorneyStates;
}