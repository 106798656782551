import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {UserProvider} from './components/global/UserProvider';
import {LegalFileProvider} from "./components/global/LegalFileProvider";
import {EstatePlanSummaryProvider} from "./components/global/EstatePlanSummaryProvider";
import {RouterProvider} from "react-router-dom";
import router from './routes';
import AlertProvider from './components/global/modals/AlertProvider';
import AHAWidget from "./AHAWidget";
import {UtilsProvider} from "./components/global/UtilsProvider";



// ReactDOM.render(<App />, document.getElementById('root'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <UtilsProvider>
          <AlertProvider>
              <UserProvider>
                  <LegalFileProvider>
                    <EstatePlanSummaryProvider>
                        <RouterProvider router={router}/>
                        <AHAWidget />
                    </EstatePlanSummaryProvider>
                  </LegalFileProvider>
              </UserProvider>
          </AlertProvider>
      </UtilsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
