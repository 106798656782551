class Api {
    //Has to be ignored because window is not recognized
    //@ts-ignore
    serverRoot = process.env.NODE_ENV === "development" ? process.env.REACT_APP_EXTERNAL_SERVER_ROOT : window.SERVER_DATA?.serverRoot;

    /**
     * Sends a POST request with the specified data to the given URL.
     * @param {any} data - The data to send in the request body.
     * @param {string} url - The URL to send the request to.
     * @returns {Promise<any>} - A Promise that resolves to the response from the server.
     * @throws {Error} - If an error occurs during the request.
     */
    post = async (data: any, url: string, isJSON: boolean = true): Promise<any> => { //Will have to be ANY since data can be structured many ways for an API Call. // TODO can we change the argument order to put the url first?

        let options: RequestInit = {
            method: 'POST',
            credentials: 'include',
            cache: "no-cache",
            body: data,
        }

        if (isJSON) {
            options.headers = { 'content-type' : 'application/json;charset=UTF-8'};
            options.body = JSON.stringify(data);
        }
        //TODO: Fix hardcoded URL
        const response: any = await window.fetch(this.serverRoot + '/api/' + url, options)
        .catch((err) => {
            throw err;
        });

        return response;
    };

    /**
     * Sends a PUT request with the specified data to the given URL.
     * @param {string} url - The URL to send the request to.
     * @param {any} data - The data to send in the request body.
     * @returns {Promise<any>} - A Promise that resolves to the response from the server.
     * @throws {Error} - If an error occurs during the request.
     */
    put = async (url: string, data: any, isJSON: boolean = true): Promise<any> => { //Will have to be ANY since data can be structured many ways for an API Call.

        let options: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            cache: "no-cache",
            body: data,
        }

        if (isJSON) {
            options.headers = { 'content-type' : 'application/json;charset=UTF-8'};
            options.body = JSON.stringify(data);
        }

        //TODO: Fix hardcoded URL
        const response: any = await window.fetch(this.serverRoot + '/api/' + url, options)
        .catch((err) => {
            throw err;
        })

        return response;
    };

    // TODO probably need a 'patch' as well?

    /**
     * Retrieves data from the specified URL.
     * @param {string} url - The URL to retrieve data from.
     * @returns {Promise<any>} - A Promise that resolves to the retrieved data.
     * @throws {Error} - If an error occurs during the retrieval process.
     */
    get = async (url: string): Promise<any> => { // TODO any data will be put on the query string

        // TODO add a data argument. This will be converted to a query string and added to any query string that may or may not already exist.

        const response: any = await window.fetch(this.serverRoot + '/api/' + url, { //TODO: Fix hardcoded URL
            method: 'GET',
            credentials: 'include',
            cache: "no-cache",

            // It is better to leave out the content-type unless it is a post or put
            // headers: {
            //     'content-type': 'application/json;charset=UTF-8',
            // }
        }).catch((err) => {
            throw err;
        })
        return response;
    };

    getDevData = async () => {
        if(!this.serverRoot || process.env.NODE_ENV !== "development"){ //Going to turn this off in production for now as it's causing too many issues. This data is still available in the window because the server injects it.
            return null;
        }

        const result = await this.get('paths/initData')
            .then(res => {
                if (!res.ok) throw new Error(res.status)
                else return res.json();
            })
            .catch(err => {
                console.error('err: ', err);
                return null;
            })

        if (!result) return;

        window.SERVER_DATA = result;
        return result;
    };

    getWhiteLabelSettings = async (whiteLabelId: number) => {
        const result = await this.get(`whitelabels/${whiteLabelId}/simple`)
            .then(res => {
                if (!res.ok) throw new Error(res.status)
                else return res.json();
            })
            .catch(err => {
                console.error('err: ', err);
                return null;
            })

        if (!result) return;
        return result;
    };

}

export default new Api();

