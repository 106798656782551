import Logo from '../Logo'

import React, {useState, useContext, useEffect, useRef} from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, UserCircleIcon } from '../icons/Icons'
import {Link} from "react-router-dom";
import {UserContext} from '../UserProvider';
import { useLocation } from 'react-router-dom';
import {EstatePlanSummaryContext} from "../EstatePlanSummaryProvider";
import useSortPackageByOwner from "../../client/useSortPackageByOwner";
import useGetAttorneyStates from "../../client/CustomHooks/useGetAttorneyStates";
import Button, {ButtonSize} from '../buttons/Button';
import {useDetermineAccess} from "../../client/CustomHooks/useDetermineAccess";
import {LegalFileContext} from "../LegalFileProvider";
import {NotifyPackageNotSignedContext} from "../../client/NotifyPackageNotSignedProvider";
import EG from "../../../helpers/EG";
import useSetHeaderHeight from "../../client/CustomHooks/useSetHeaderHeight";
import Definitions from '../../../definitions';


const clientNavigation = [
    { name: 'Dashboard', href: '', completeTriageFirst: false },
    { name: 'Plan Participants', href: 'participants', completeTriageFirst: true },
    { name: 'Assets', href:  'assets', completeTriageFirst: true },
    { name: 'Deeds', href:  'deeds', completeTriageFirst: true },
]

const partnerNavigation = [
    { name: 'Clients', href: '', completeTriageFirst: false },
    { name: 'Resources', href:  'resources', completeTriageFirst: false }
]

const adminNavigation = [
    { name: 'Admin', href: '', completeTriageFirst: false },
    { name: 'Attorneys', href: 'attorney', completeTriageFirst: false },
    { name: 'White Labels', href: 'whitelabels', completeTriageFirst: false },
    { name: 'Reports', href: 'reports', completeTriageFirst: false },
]


export default function Header() {
    const location = useLocation();
    const [estatePlanSummary, setEstatePlanSummary] = useContext(EstatePlanSummaryContext);
    const [, setLegalFile] = useContext(LegalFileContext);
    const [user, setUser] = useContext(UserContext);
    const [, setShowNotifyPackageNotSigned] = useContext(NotifyPackageNotSignedContext);
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];
    const packages: any = useSortPackageByOwner();
    const attorneyStates: string[] | null = useGetAttorneyStates();
    const [navigationMap] = useState<any[]>(clientNavigation);
    const headerRef = useRef<HTMLDivElement | null>(null);
    useSetHeaderHeight(headerRef);

    const getTabNameFromUrlPath = () => {
        const matchingTab = navigationMap.find(tab => tab.href === lastSegment);
        return matchingTab ? matchingTab.name : 'Dashboard';
    };

    const [selectedTab, setSelectedTab] = useState(getTabNameFromUrlPath());
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [triageComplete, setTriageComplete] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [stateAvailable, setStateAvailable] = useState<boolean>(false);
    const { isStakeholder, hasAnyAssetsAccess } = useDetermineAccess();

    useEffect(() => {
        setSelectedTab(getTabNameFromUrlPath());
    }, [lastSegment]);

    useEffect(() => {
        if (!packages || !estatePlanSummary || !attorneyStates) {
            setIsLoading(true)
        } else {
            if (estatePlanSummary.triage?.firstName !== undefined) {
                setTriageComplete(true);
            }
            if (attorneyStates.includes(estatePlanSummary.triage?.state) || estatePlanSummary.triage?.firstName === undefined) {
                setStateAvailable(true);
            }
            setIsLoading(false);
        }
    }, [packages, estatePlanSummary, attorneyStates]);

    const hasWillAlias = (packages: any[]) => {
        if (!packages?.length) return;
        return packages.some((pkg: any) => pkg.alias === "will");
    }

    const showLink = (item: any) => {
        const hideAssetsAndDeedsOnly = (link: any) => {
            return !['Dashboard', 'Plan Participants'].includes(link.name)
        }

        const hideDeedsOnly = (link: any) => {
            return !['Dashboard', 'Plan Participants', 'Assets'].includes(link.name);
        }

        let show = true;
        if (!stateAvailable) { // hide all links if state isn't available
            show = false;
        }
        if (!triageComplete && item.completeTriageFirst) { // hide all links if triage isn't complete
            show = false;
        }
        if (hasWillAlias(packages?.primary) && hideAssetsAndDeedsOnly(item)) { // hide "Assets" and "Deeds" if it's a Will package
            show = false;
        }
        if (packages.joint?.[0]?.alias === "none" && hideAssetsAndDeedsOnly(item)) { // hide "Assets" and "Deeds" if joint package alias === "none"
            show = false;
        }
        if (isStakeholder && !hasAnyAssetsAccess && hideAssetsAndDeedsOnly(item)) {
            show = false;
        }
        if (isStakeholder && hideDeedsOnly(item)) {
            show = false;
        }
        if (isStakeholder && item.name === "Plan Participants") { // hide "Plan Participants" if the user is a stakeholder
            show = false;
        }

        return show;
    }

    const handleCloseTab = () => {
        window.close();
    }

    const shouldShowCloseWindowButton = (): boolean => {
        let shouldShow = false;
        if (user?.systemPrivs) {
            const roleStrings = [Definitions.roles.sysAdmin.name, Definitions.roles.admin.name, Definitions.roles.advisor.name];
            const containsRole = user?.systemPrivs?.roles?.some((item: string) => roleStrings.includes(item));
            if (containsRole) {
                shouldShow = true;
            }
        }
        return shouldShow;
    }

    return (
        <>
            {
                isLoading ||
                    <div ref={headerRef}>
                        <header className={'bg-headerColor shadow-md w-full pr-[32px] pl-[24px] py-2'}>
                            <nav className="flex items-center justify-between lg:px-8 min-h-[50px] max-h-headerMaxHeight" aria-label="Global">
                                <Link to={''} className="py-1.5 text-secondary">
                                    <Logo type={'noName'} additionalClasses={'w-12 h-12'}/>
                                </Link>
                                <div className="flex md:hidden std:hidden lg:hidden xl:hidden">
                                    <button
                                        type="button"
                                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                        onClick={() => setMobileMenuOpen(true)}
                                    >
                                        <span className="sr-only">Open main menu</span>
                                        <Bars3Icon className="w-6 text-headerElementsColor" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="hidden md:flex std:flex lg:flex xl:flex gap-x-[98px] items-center">
                                    <div className={'flex gap-x-[24px] items-center'}>
                                        {navigationMap.map((item) => (
                                            showLink(item) &&
                                                <Link
                                                    key={item.name}
                                                    to={item.href + ''}
                                                    className={
                                                        `${selectedTab === item.name ? `underline underline-offset-headerUnderlineOffset` : ""}
                                                        text-sm font-semibold leading-6 text-headerElementsColor hover:underline underline-offset-headerUnderlineOffset decoration-4 decoration-secondary`
                                                    }
                                                >
                                                    {item.name}
                                                </Link>
                                        ))}
                                    </div>
                                    <div className={'flex border-l pl-5 gap-x-4 py-2 justify-end items-center '}>
                                        <div className={'flex gap-x-4'}>
                                            {(user.user.userData?.enableBetaEnvironment === '1' || window.SERVER_DATA.whiteLabel.settings.enableBetaEnvironment === '1') && (
                                                <div className={'flex items-center'}>
                                                    <Button
                                                        className={'!border-headerElementsColor !text-headerElementsColor !bg-transparent'}
                                                        id={'goToClassicButtonClient'}
                                                        buttonSize={ButtonSize.ExtraSmall}
                                                        onClick={() => EG.goToClassicView(user.user, "/#plan")}
                                                    >
                                                        Classic View
                                                    </Button>
                                                </div>
                                            )}
                                            <div className={'flex items-center p-2'}>
                                                <Link to={'/app/client/profile'}>
                                                    <UserCircleIcon
                                                        className={'w-6 hover:bg-headerElementsColor hover:text-headerColor text-headerElementsColor hover:rounded-full'}/>
                                                </Link>
                                            </div>
                                        </div>
                                        {shouldShowCloseWindowButton() ?
                                            <span
                                                onClick={() => handleCloseTab()}
                                                className="text-sm font-semibold leading-6 cursor-pointer text-headerElementsColor px-2"
                                            >
                                                Close
                                            </span>
                                            :
                                            <span
                                                onClick={() => EG.logout([setLegalFile, setEstatePlanSummary, setShowNotifyPackageNotSigned, setUser])}
                                                className="text-sm font-semibold leading-6 cursor-pointer text-headerElementsColor px-2"
                                            >
                                                Logout
                                            </span>
                                        }
                                        {/*TODO: Remove the button below once the redesign is out of Beta*/}
                                    </div>
                                </div>
                            </nav>
                            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                                <div className="fixed inset-0 z-10"/>
                                <Dialog.Panel
                                    // @ts-ignore
                                    focus="true"
                                    className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-headerColor px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                                >
                                    <div className="flex items-center justify-between">
                                        <a href="#" className="-m-1.5 p-1.5">
                                            <span className="sr-only">Estate Guru</span>
                                            <Logo type={'noName'} additionalClasses={'w-12 h-[50px]'}/>
                                        </a>
                                        <button
                                            type="button"
                                            className="-m-2.5 rounded-md p-2.5 text-headerElementsColor"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <span className="sr-only">Close menu</span>
                                            <XMarkIcon className="w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="mt-6 flow-root">
                                        <div className="-my-6 divide-y divide-gray-500/10">
                                            <div className="space-y-2 py-6">
                                                {navigationMap.map((item) => (
                                                    showLink(item) &&
                                                        <Link key={item.name} to={item.href + ''}
                                                              className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-headerElementsColor">
                                                            {item.name}
                                                        </Link>
                                                ))}
                                            </div>
                                            <div className="py-6">
                                                <a
                                                    href="#"
                                                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-headerElementsColor"
                                                >
                                                    Log in
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Dialog>
                        </header>
                    </div>
            }
        </>
    )
}


export function AdminHeader() {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];
    const [navigationMap] = useState<any[]>(adminNavigation);

    const getTabNameFromUrlPath = () => {
        const matchingTab = navigationMap.find(tab => tab.href === lastSegment);
        return matchingTab ? matchingTab.name : 'Admin';
    };

    const [selectedTab, setSelectedTab] = useState(getTabNameFromUrlPath());
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [user, setUser] = useContext(UserContext);
    const headerRef = useRef<HTMLDivElement | null>(null);
    useSetHeaderHeight(headerRef);

    useEffect(() => {
        setSelectedTab(getTabNameFromUrlPath());
    }, [lastSegment]);

    return (
        <>
            <div ref={headerRef}>
                <header className={'bg-headerColor shadow-md w-full pr-[32px] pl-[24px] py-2'}>
                    <nav className="mx-auto flex items-center justify-between lg:px-8 min-h-[50px] max-h-headerMaxHeight" aria-label="Global">
                        <Link to={''} className="py-1.5 text-secondary">
                            <Logo type={'noName'} additionalClasses={'w-12 h-12'}/>
                        </Link>
                        <div className="flex md:hidden std:hidden lg:hidden xl:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="w-6 text-headerElementsColor" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="hidden md:flex std:flex lg:flex xl:flex align-bottom gap-x-[98px] items-center">
                            <div className={'flex gap-x-[24px] items-center'}>
                                {navigationMap.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href + ''}
                                        className={`${selectedTab === item.name ? "underline underline-offset-headerUnderlineOffset" : ""} text-sm font-semibold leading-6 text-headerElementsColor hover:underline underline-offset-headerUnderlineOffset decoration-4 decoration-secondary`}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <div className={'flex border-l pl-5 items-center py-2 gap-x-4'}>
                                {(user.user.userData?.enableBetaEnvironment === '1' || window.SERVER_DATA.whiteLabel.settings.enableBetaEnvironment === '1') && (
                                    <div className={'flex items-center'}>
                                        <Button
                                            className={'!border-headerElementsColor !text-headerElementsColor !bg-transparent'}
                                            id={'goToClassicButtonPartner'} buttonSize={ButtonSize.ExtraSmall}
                                            onClick={() => EG.goToClassicView(user.user, "/")}>Classic View</Button>
                                    </div>
                                )}
                                <div className={'flex items-center p-2'}>
                                    <Link to={'/app/sys/profile'}>
                                        <UserCircleIcon
                                            className={'w-6 hover:bg-headerElementsColor hover:text-headerColor text-headerElementsColor hover:rounded-full'}
                                        />
                                    </Link>
                                </div>
                                <span onClick={() => EG.logout([setUser])}
                                      className="text-sm font-semibold leading-6 cursor-pointer text-headerElementsColor px-[8px]">
                                    Logout
                                </span>
                            </div>
                        </div>
                    </nav>
                    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <div className="fixed inset-0 z-10"/>
                        <Dialog.Panel
                            // @ts-ignore
                            focus="true"
                            className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-headerColor px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                        >
                            <div className="flex items-center justify-between">
                                <a href="#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Estate Guru</span>
                                    <Logo type={'noName'} additionalClasses={'w-12 h-[50px]'}/>
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-headerElementsColor"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="w-6" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-headerElementsColor">
                                    <div className="space-y-2 py-6">
                                        {navigationMap.map((item) => (
                                            <Link key={item.name} to={item.href + ''}
                                                  className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-headerElementsColor">
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        <a
                                            href="#"
                                            className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-headerElementsColor"
                                        >
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </header>
            </div>
        </>
    )
}


export function PartnerHeader() {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];
    const [navigationMap] = useState<any[]>(partnerNavigation);

    const getTabNameFromUrlPath = () => {
        const matchingTab = navigationMap.find(tab => tab.href === lastSegment);
        return matchingTab ? matchingTab.name : 'Clients';
    };

    const [selectedTab, setSelectedTab] = useState(getTabNameFromUrlPath());
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [user, setUser] = useContext(UserContext);
    const headerRef = useRef<HTMLDivElement | null>(null);
    useSetHeaderHeight(headerRef);

    useEffect(() => {
        setSelectedTab(getTabNameFromUrlPath());
    }, [lastSegment]);

    return (
        <>
            <div ref={headerRef}>
                <header className={'bg-headerColor shadow-md w-full pr-[32px] pl-[24px] py-2'}>
                    <nav className="mx-auto flex items-center justify-between lg:px-8 min-h-[50px] max-h-headerMaxHeight" aria-label="Global">
                        <Link to={''} className="py-1.5 text-secondary">
                            <Logo type={'noName'} additionalClasses={'w-12 h-12'}/>
                        </Link>
                        <div className="flex md:hidden std:hidden lg:hidden xl:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="w-6 text-headerElementsColor" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="hidden md:flex std:flex lg:flex xl:flex align-bottom gap-x-[98px] items-center">
                            <div className={'flex gap-x-[24px] items-center'}>
                                {navigationMap.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href + ''}
                                        className={`${selectedTab === item.name ? "underline underline-offset-headerUnderlineOffset" : ""} text-sm font-semibold leading-6 text-headerElementsColor hover:underline underline-offset-headerUnderlineOffset decoration-4 decoration-secondary`}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <div className={'flex border-l pl-5 items-center py-2 gap-x-4'}>
                                {(user.user.userData?.enableBetaEnvironment === '1' || window.SERVER_DATA.whiteLabel.settings.enableBetaEnvironment === '1') && (
                                    <div className={'flex items-center'}>
                                        <Button
                                            className={'!border-headerElementsColor !text-headerElementsColor !bg-transparent'}
                                            id={'goToClassicButtonPartner'}
                                            buttonSize={ButtonSize.ExtraSmall}
                                            onClick={() => EG.goToClassicView(user.user, "/")}
                                        >
                                            Classic View
                                        </Button>
                                    </div>
                                )}
                                <div className={'flex items-center p-2'}>
                                    <Link to={'/app/partner/profile'}>
                                        <UserCircleIcon
                                            className={'w-6 hover:bg-headerElementsColor hover:text-headerColor text-headerElementsColor hover:rounded-full'}
                                        />
                                    </Link>
                                </div>
                                <span onClick={() => EG.logout([setUser])}
                                      className="text-sm font-semibold leading-6 cursor-pointer text-headerElementsColor px-[8px]">
                                    Logout
                                </span>
                            </div>
                        </div>
                    </nav>
                    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <div className="fixed inset-0 z-10"/>
                        <Dialog.Panel
                            // @ts-ignore
                            focus="true"
                            className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-headerColor px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                        >
                            <div className="flex items-center justify-between">
                                <a href="#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Estate Guru</span>
                                    <Logo type={'noName'} additionalClasses={'w-12 h-[50px]'}/>
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-headerElementsColor"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="w-6" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-headerElementsColor">
                                    <div className="space-y-2 py-6">
                                        {navigationMap.map((item) => (
                                            <Link key={item.name} to={item.href + ''}
                                                  className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-headerElementsColor">
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        <a
                                            href="#"
                                            className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-headerElementsColor"
                                        >
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </header>
            </div>
        </>
    )
}
