import {EGPartnerBase} from "./EGPartnerBase";
import Definitions from "./../definitions"

export class LinkPartner extends EGPartnerBase {
    cache: { [key: string]: any[] } = {};  // user-name: [estatePlanTypes]

    showUsernamePassword(): boolean {
        return true;
    }

    showOAuthLogin(): any {  // Override should return string for button label
        return "Sign in with Link by LegacyShield";
    }

    willPriceDescriptor(isMarried: boolean, willProduct1: any, willProduct2: any): string {
        let result = '';
        if (willProduct1?.price === 0) {
            result += "<p>Your Will is provided to you at no charge.</p>";
        } else {
            result += `<p>The price of your Will is ${Definitions.formatMoney(willProduct1.price)}.</p>`;
        }

        if (isMarried && willProduct2?.price !== 0) {
            result += `<p>Your spouse can also create their own Premium Will. The cost for your spouse's Will is ${Definitions.formatMoney(willProduct2.price)}.  (There is no second option for a Simple Will.)  They will be prompted for payment as part of completing their Will document.</p>`;
        }

        return result;
    }

    // @ts-ignore
    getExtraSuccessorTrustee(userData: any): any {
        const value = userData?.defaultSuccessorTrustee || null;
        return value;
    }

    allowSimpleWill(scope: string): boolean {
        return scope === 'self';  // only allowed for the primary user
    }

    allowMST(): boolean {
        return true;
    }
}