// TODO: Implement icon left and right side

import React from "react";
import {Link} from "react-router-dom";
import {ChevronRightIcon, ChevronLeftIcon, LoadingIcon} from "../icons/Icons";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export enum ButtonType {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    SpecialCTA = 'specialCTA'
}

export enum ButtonSize {
    ExtraSmall = 'xs',
    Small = 'sm',
    Medium = 'md',
    Large = 'lg'
}

interface ButtonProps {
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) => void;
    forNavigation?: boolean;
    navigateTo?: string;
    navigationData?: object;
    buttonSize?: ButtonSize;
    buttonType?: ButtonType;
    loading?: 'left' | 'right';
    autoFocus?: boolean;
    id?: string;
    className?: string;
    name?: string;
    hidden?: boolean;
    arrow?: 'left' | 'right';
    isBusy?: boolean; //Disable but don't show disabled state
    title?: string;
}

const Button: React.FC<ButtonProps> = ({
                                           children,
                                           disabled = false,
                                           onClick,
                                           buttonSize = ButtonSize.Medium,
                                           buttonType = ButtonType.Primary,
                                           loading,
                                           autoFocus,
                                           id,
                                           className,
                                           forNavigation,
                                           navigateTo,
                                           navigationData,
                                           name,
                                           hidden = false,
                                           arrow = undefined,
                                           isBusy,
                                           title
                                       }) => {

    // Override old sizes (I rather do it this way than go and remove lots of lines of code everywhere. Makes easier to test and also to change back if they change their minds)
    // NOTE: the new design only has two sizes, small and medium so we treat xs as small and large as medium
    if (buttonSize === ButtonSize.ExtraSmall) {
        buttonSize = ButtonSize.Small;
    }
    else if (buttonSize === ButtonSize.Large) {
        buttonSize = ButtonSize.Medium;
    }

    const getColorClass = () => {
        let disabledClasses, normalClasses, hoverClasses;

        if (buttonType === ButtonType.Primary) {
            disabledClasses = 'disabled:bg-neutralSecondary-100 disabled:border-neutralPrimary-300 disabled:text-neutralPrimary-300';
            normalClasses = "bg-primary border border-primary text-neutralSecondary-0";
            hoverClasses = "hover:bg-secondary-300 hover:text-primary";
        } else if (buttonType === ButtonType.SpecialCTA) {
            disabledClasses = "disabled:bg-neutralPrimary-200 disabled:text-neutralPrimary";
            normalClasses = "bg-tertiary-900 text-primary border border-tertiary-900";
            hoverClasses = "hover:border hover:border-tertiary-100";
        } else if (buttonType === ButtonType.Secondary) {
            disabledClasses = 'disabled:text-neutralPrimary-300 disabled:bg-neutralSecondary-0';
            normalClasses = 'border border-primary text-primary bg-neutralSecondary-0';
            hoverClasses = 'hover:bg-neutralPrimary-200';
        } else if (buttonType === ButtonType.Success) {
            disabledClasses = 'disabled:text-neutralPrimary-300 disabled:bg-neutralSecondary-0';
            normalClasses = 'border border-success-200 bg-success-200 text-success-100';
            hoverClasses = 'hover:bg-success-100 text-success-color-200';
        }
        return normalClasses + " " + hoverClasses + " " + disabledClasses;
    }

    const getFontSize = () => {
        if (buttonSize === ButtonSize.Small || buttonSize === ButtonSize.ExtraSmall) {
            return 'text-sm';
        }
        if (buttonSize === ButtonSize.Medium || buttonSize === ButtonSize.Large) {
            return 'text-base';
        }
        // Default case
        return 'text-base';
    };

    const getIconSize = () => {
        if (!buttonSize) return;
        switch (buttonSize) {
            case ButtonSize.ExtraSmall:
                return "w-4";
            case ButtonSize.Small:
                return "w-4";
            case ButtonSize.Medium:
                return "w-4";
            case ButtonSize.Large:
                return "w-4";
            default:
                return "w-4";
        }
    }

    const getPaddingX = () => {
        switch(arrow) {
            case "left":
                return "pl-2 pr-4";
            case "right":
                return "pr-2 pl-4";
            default:
                return "px-4";
        }
    }

    const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        if (!disabled && !loading && !isBusy && onClick) {
            onClick(event);
        }
    };

    const content = () => {
        if (loading === "right") {
            return (
                <div className="flex items-center">
                    {children}
                    <span className={"ml-1"}>
                        <LoadingIcon className={`${getIconSize()}`} />
                    </span>
                </div>
            )
        } else if (loading === "left") {
            return (
                <div className="flex items-center">
                    <span className={"pr-1"}>
                        <LoadingIcon className={`${getIconSize()}`} />
                    </span>
                    {children}
                </div>
            )
        } else if (arrow === "left") {
            return (<><ChevronLeftIcon className={"mr-1 " + getIconSize()}/><span>{children}</span></>)
        } else if (arrow === "right") {
            return (<><span className="mr-1">{children}</span><ChevronRightIcon className={getIconSize()}/></>)
        } else {
            return (children);
        }
    }

    return (
        <div hidden={hidden}>
            {forNavigation ?

                <Link
                    to={navigateTo ?? ""}
                    state={navigationData}
                    id={id}
                    title={title}
                    className={classNames(getFontSize(), getColorClass(), `py-2 ${getPaddingX()} rounded-full max-w-fit font-semibold flex items-center hover:shadow-md active:border-tertiary-100`, className ?? '')}
                    onClick={handleOnClick}
                >
                    {content()}
                </Link>
                :
                <div className="flex items-center">
                    <button
                        id={id}
                        name={name}
                        className={classNames(getFontSize(), getColorClass(), `py-2 ${getPaddingX()} rounded-full font-semibold flex items-center hover:shadow-md active:border-tertiary-100 ${isBusy || loading ? "cursor-progress" : disabled ? "cursor-not-allowed" : ""}`, className ?? '')}
                        onClick={handleOnClick}
                        disabled={disabled}
                        title={title}
                        autoFocus={autoFocus ? true : undefined}
                    >
                        {content()}
                    </button>
                </div>
            }
        </div>
    );
};

export default Button;