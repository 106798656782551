import Definitions from './../definitions'
// TODO: Any functions commented out should be implemented and uncommented. I commented them out since I cant build those yet in the react project.

export class EGPartnerBase {
    onLoginViewAttached(): void {
        // NOTHING
    }

    includeTriagePaymentProcess(): boolean {
        return false;
    }

    excludesUserNameCreation(): boolean {
        return false;
    }

    shouldNavigateToTriagePayment(whiteLabel: any, estatePlanType: string): boolean {
        return estatePlanType !== Definitions.estatePlanTypes.None && whiteLabel.settings?.collectTriagePayment === '1';
    }

    //TODO: Implement this
    // navigateToTriagePayment(whiteLabel: any, triageSummary: any, parent: any): void {
    //     return this.navigateToTriageMakePayment(whiteLabel, triageSummary, parent);
    // }

    hasSelectedSimpleWill(triageSummary: any): any {
        if (
            triageSummary.estatePlanType === Definitions.estatePlanTypes.ConventionalWill &&
            triageSummary.triage.simpleWillOverride === '1'
        ) {
            const packageDef = Definitions.packages.find((item: any) => item.alias === Definitions.packageList.SimpleWill);
            return packageDef;
        }
    }

    needToPurchaseProduct(estatePlanType: string, summary: any, whiteLabel: any): Promise<boolean> {
        let thePackage = summary.packages?.find((item: any) => item.alias === estatePlanType);
        if (this.hasSelectedSimpleWill(summary)) {
            thePackage = summary.packages?.find((item: any) => item.alias === Definitions.packageList.SimpleWill);
            // ...
        }

        const purchasedVersion = thePackage?.status?.find((item: any) => item.everPurchased);
        const result = this.shouldNavigateToTriagePayment(whiteLabel, estatePlanType) && !purchasedVersion;
        return Promise.resolve(result);
    }

    //TODO: Implement this:
    // navigateToTriageMakePayment(whiteLabel: any, triageSummary: any, parent: any): void {
    //     const email = triageSummary.people.find((item: any) => item.id === triageSummary?.ownerIdPrimary)?.email1;
    //     const packageDef = window.Definitions.packages.find((item: any) => item.alias === triageSummary.estatePlanType);
    //
    //     if (this.hasSelectedSimpleWill(triageSummary)) {
    //         const packageDef = window.Definitions.packages.find((item: any) => item.alias === window.Definitions.packageList.SimpleWill);
    //         // ...
    //     }
    //
    //     const pkg = triageSummary.packages.find((item: any) => item.alias === packageDef.alias);
    //
    //     const packageVersionIds: any[] = [];
    //     if (packageDef.scope === 'individual') {
    //         const pkgStatus = pkg.status.find((item: any) => item.ownerId === triageSummary.ownerIdPrimary);
    //         packageVersionIds.push(pkgStatus?.latestPackageVersionId);
    //     } else {
    //         const pkgStatus = pkg.status.find((item: any) => item.ownerId === null);
    //         packageVersionIds.push(pkgStatus?.latestPackageVersionId);
    //     }
    //
    //     const guideMeOpt = window.whiteLabel.settings?.enableGuideMe === '1' ? 'allowed' : 'none';
    //
    //     //TODO: this should return the jsx instead
    //     EG.createView('MakePayment', {
    //         email: email,
    //         packageVersionIds: packageVersionIds,
    //         packageDef: packageDef,
    //         guideMeOpt: guideMeOpt,
    //         whiteLabelId: triageSummary.whiteLabelId,
    //         estatePlanId: triageSummary.id,
    //         title: `Purchase your ${packageDef.name}`,
    //         showNavButtons: true,
    //         parent: parent,
    //     });
    // }

    onTriagePaymentResult(params: any): void {
        // NOTHING
    }

    // TODO: Implement this
    // apiLogin(username: string, password: string): void {
    //     EG.login(username, password);
    // }

    trustPriceDescriptor(): string {
        return '';
    }

    willPriceDescriptor(isMarried: boolean, willProduct1: any, willProduct2: any): string {
        return '';
    }

    partnerTrustTitle(trustType: string, isMarried: boolean, recommend: boolean = true): string {
        let recommended = recommend ? "<span class=\"float-right italic\">(Recommended)</span>" : '';
        let title = 'Revocable Living Trust';

        switch (trustType) {
            case Definitions.estatePlanTypes.SinglePersonTrust:
                title = 'Revocable Living Trust';
                break;
            case Definitions.estatePlanTypes.MarriedJointTrust:
                title = 'Married Joint Revocable Trust';
                break;
            case Definitions.estatePlanTypes.MarriedJointTrustAB:
                title = 'Married Joint Revocable Trust';
                break;
            case Definitions.estatePlanTypes.MarriedSeparateTrust:
                title = 'Married Separate Revocable Trusts - one for each spouse';
                recommended = '';
                break;
        }

        return `<span class="float-left">${title}</span> ${recommended}<div style="clear: both;"></div>`;
    }

    partnerWillTitle(isMarried: boolean): string {
        let result = 'Conventional Will';
        if (isMarried) {
            result += ' - one for each spouse';
        }
        return result;
    }

    partnerSimpleWillTitle(isMarried: boolean): string {
        let result = 'Simple Will';
        if (isMarried) {
            result += ' - one for each spouse';
        }
        return result;
    }

    allowMST(): boolean {
        return true;
    }

    showIframeInNewTab(): boolean {
        return false;
    }

    showUsernamePassword(): boolean {
        return true;
    }

    showOAuthLogin(): boolean {
        return false;
    }

    getExtraSuccessorTrustee(): any {
        return null;
    }

    allowSimpleWill(scope: string): boolean {
        return false;
    }

    exposePartnerId(): boolean {
        return false;
    }

    partnerIdRequired(): boolean {
        return true;
    }
}
