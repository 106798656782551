import React, {createContext, useContext, useEffect, useState} from "react";
import { Deed } from "../client/views/DeedController";
import {LegalFileContext} from "./LegalFileProvider";
import {EstatePlanSummaryContext} from "./EstatePlanSummaryProvider";
import Definitions from '../../definitions';
import {useNavigate} from "react-router-dom";
import {useDetermineAccess} from "../client/CustomHooks/useDetermineAccess";

interface DeedDataProviderProps {
    children: React.ReactNode
}

export const DeedDataContext = createContext<any>(null); //TODO: don't use any. context is using some complex logic here and is throwing errors when giving a specific type.

export function DeedDataProvider ({children}: DeedDataProviderProps) {
    const [legalFile, setLegalFile] = useContext(LegalFileContext);
    const {isStakeholder} = useDetermineAccess();
    const [estatePlanSummary] = useContext(EstatePlanSummaryContext);
    const navigate = useNavigate();
    const [deedList, setDeedList] = useState< Deed[] | []>([]);
    const [contactEmail, setContactEmail] = useState<string>('')
    const [contactPhone, setContactPhone] = useState<string>('')
    const [currentDeedIndex, setCurrentDeedIndex] = useState<number>(0)

   // useEffect(() => {
   //     //Update LegalFile in DB whenever legalfile state is changed.
   //     if(deedList.length){
   //         let deedListCopy = deedList.slice();
   //         deedListCopy.forEach( (deed) => deed.contactEmail = contactEmail)
   //         setDeedList(deedListCopy);
   //     }
   //
   // }, [contactEmail])
   //
   //  useEffect(() => {
   //      //Update LegalFile in DB whenever legalfile state is changed.
   //      if(deedList.length){
   //          let deedListCopy = deedList.slice();
   //          deedListCopy.forEach( (deed) => deed.contactPhone = contactPhone)
   //          setDeedList(deedListCopy);
   //      }
   //
   //  }, [contactPhone])

    // NOTE: I commented this out because it breaks the nwe deeds logic. The new deedLanding page doesnt use DeedDataContext anymore
    // useEffect(() => {
    //     if (legalFile === null) {
    //         return;
    //     } else {
    //         const completeDeedList = legalFile.getLegalFile('common').get(Definitions.legalFileFields.deed.name) ?? [];
    //         setDeedList(completeDeedList.filter( (deed: Deed) => [Definitions.deedStatus.initial, Definitions.deedStatus.purchased].includes(deed.status ?? "")))
    //     }
    // }, [legalFile]);

    if (isStakeholder && estatePlanSummary.deedCount === 0) {
        navigate('/app/client');
    }

    return (
        <DeedDataContext.Provider value={{deedList, setDeedList, contactEmail, setContactEmail, contactPhone, setContactPhone, currentDeedIndex, setCurrentDeedIndex}}>
            {children}
        </DeedDataContext.Provider>
    );
}