import {useEffect, MutableRefObject} from "react";
import useObserveSize from "./useObserveSize";

export default function useSetHeaderHeight(headerRef: MutableRefObject<HTMLElement | null>) {
    const headerSize = useObserveSize(headerRef);

    useEffect(() => {
        if (headerSize?.height) {
            const root = document.querySelector(':root') as HTMLElement;
            root.style.setProperty('--header-height', `${headerSize.height}px`);
            const fontSizeString = window.getComputedStyle(document.documentElement).getPropertyValue('font-size');
            const fontSize = Number(fontSizeString.substring(0, fontSizeString.length - 2));
            root.style.setProperty('--header-underline-offset', `${(headerSize.height/2) - ((0.875 * fontSize) - 5)}px`);
        }
    }, [headerSize?.height]);

    return
}
