import loader from './helpers/LoaderHelper';
import SideBar from "./components/client/pages/SideBar";
import Profile from "./components/client/pages/Profile";
import {createBrowserRouter} from "react-router-dom";
import Layout from "./components/client/pages/Layout";
import React from "react";
import PrivateRoute from "./components/global/navigation/PrivateRoute";
import EG from "./helpers/EG";
import Button, {ButtonSize, ButtonType} from "./components/global/buttons/Button";
import {DeedDataProvider} from "./components/global/DeedDataProvider";
import NotFound from "./components/global/pages/NotFound";
import {GuideProvider} from "./components/global/GuideProvider";
import Divider from "./components/global/Divider";

const router = createBrowserRouter([
    {
        path: '/app',
        async lazy() {
            let {AppRedirect} = await import("./components/global/pages/AppRedirect");
            return {
                Component: () => <AppRedirect/>,
            }
        },
        loader: loader('login')
    },
    {
        path: '/app/client',
        async lazy() {
            let {ClientApp} = await import("./clientPortalRouteComponents");
            return {
                Component: () => <PrivateRoute component={<GuideProvider><ClientApp /></GuideProvider>} allowedRoles={EG.clientAllowedRoles} />,
            }
        },
        loader: loader('client'),
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        index: true,
                        async lazy() {
                            let {ClientDashboard} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<ClientDashboard />} allowedRoles={EG.clientAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'assets',
                        async lazy() {
                            let {AssetDetails} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AssetDetails />} allowedRoles={EG.clientAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'deeds',
                        async lazy() {
                            let {DeedLanding} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <DeedDataProvider><PrivateRoute component={<DeedLanding/>} allowedRoles={EG.clientAllowedRoles} /></DeedDataProvider>,
                            }
                        },
                    },
                    {
                        path: 'participants',
                        async lazy() {
                            let {PlanParticipantsDashboard} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PlanParticipantsDashboard />} allowedRoles={EG.clientAllowedRoles} preventStakeholderAccess={true} />,
                            }
                        },
                    },
                    {
                        path: "*",
                        element: <NotFound />
                    }
                ]
            },
            {
                element: <PrivateRoute component={<SideBar children={<Profile/>}/>} allowedRoles={EG.clientAllowedRoles} />,
                path: 'profile',
                children: [
                    {
                        index: true,
                        async lazy() {
                            let {MyProfile} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<MyProfile />} allowedRoles={EG.clientAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'document-history',
                        async lazy() {
                            let {DocumentHistory} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<DocumentHistory />} allowedRoles={EG.clientAllowedRoles} preventStakeholderAccess={true} />,
                            }
                        },
                    },
                    {
                        path: 'my-subscription',
                        async lazy() {
                            let {MySubscription} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<MySubscription />} allowedRoles={EG.clientAllowedRoles} preventStakeholderAccess={true} />,
                            }
                        },
                    },
                    {
                        path: 'account-password',
                        async lazy() {
                            let {AccountPassword} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AccountPassword />} allowedRoles={EG.clientAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'account-security',
                        async lazy() {
                            let {AccountSecurity} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AccountSecurity />} allowedRoles={EG.clientAllowedRoles} />,
                            }
                        },
                    }
                ]
            },
            {
                path: 'questionnaire',
                async lazy() {
                    let {PackageController} = await import("./clientPortalRouteComponents");
                    return {
                        Component: () => <PrivateRoute component={<PackageController />} allowedRoles={EG.clientAllowedRoles} />,
                    }
                },
            },
            {
                path: 'deed-questionnaire',
                async lazy() {
                    let {DeedController} = await import("./clientPortalRouteComponents");
                    return {
                        Component: () => <PrivateRoute component={<DeedDataProvider><DeedController /></DeedDataProvider>} allowedRoles={EG.clientAllowedRoles} />,
                    }
                },
            },
            {
                path: 'upload-signature-pages', // TODO support this and coming directly from the package
                element: <PrivateRoute component={<SideBar children={
                    <div>
                        <Divider/>
                        <div className="w-full flex justify-center items-center">
                            <Button className={"flex items-center"} forNavigation={true} navigateTo={'/app/client'}
                                    arrow={"left"}
                                    buttonType={ButtonType.Secondary}
                                    buttonSize={ButtonSize.ExtraSmall}>
                                Close
                            </Button>
                        </div>
                        <Divider/>
                    </div>
                }/>} allowedRoles={EG.clientAllowedRoles}/>,
                children: [
                    {
                        index: true,
                        async lazy() {
                            let {SignaturePageUpload} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<SignaturePageUpload/>}
                                                               allowedRoles={EG.clientAllowedRoles}/>,
                            }
                        },
                    }
                ]
            },
            {
                path: 'participant-profile',
                element: <PrivateRoute component={<SideBar children={<Button forNavigation={true} navigateTo={'/app/client/participants'} buttonSize={ButtonSize.Small} className={"mt-8"} arrow={"left"}> Return to plan participants</Button>} />} allowedRoles={EG.clientAllowedRoles} />,
                children: [
                    {
                        index: true,
                        async lazy() {
                            let {ParticipantProfile} = await import("./clientPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<ParticipantProfile />} allowedRoles={EG.clientAllowedRoles} />,
                            }
                        },
                    }
                ]
            },
            {
                path: 'new',
                async lazy() {
                    let {TriageController} = await import("./clientPortalRouteComponents");
                    return {
                        Component: () => <PrivateRoute component={<TriageController />} allowedRoles={EG.clientAllowedRoles} />,
                    }
                },
            },
            {
                path: 'update-my-plan',
                async lazy() {
                    let {TriageController} = await import("./clientPortalRouteComponents");
                    return {
                        Component: () => <PrivateRoute component={<TriageController editTriage={true} />} allowedRoles={EG.clientAllowedRoles} />,
                    }
                },
            },
        ]
    },
//     TODO: add adminApp and advisorApp and whatever else we come up with here
    {
        path: '/app/partner',
        async lazy() {
            let {PartnerApp} = await import("./partnerPortalRouteComponents");
            return {
                Component: () => <PrivateRoute component={<PartnerApp />} allowedRoles={EG.partnerAllowedRoles} />,
            }
        },
        loader: loader('partner'),
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        index: true,
                        async lazy() {
                            let {PartnerClientDashboard} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerClientDashboard />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: "resources",
                        async lazy() {
                            let {PartnerResources} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerResources />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: "*",
                        element: <NotFound />
                    }
                ]
            },
            {
                path: 'client',
                element: <PrivateRoute component={<SideBar children={
                    <div>
                        <Divider />
                        <div className="w-full flex justify-center items-center">
                            <Button className={"flex items-center"} forNavigation={true} navigateTo={'/app/partner'} arrow={"left"}
                                    buttonType={ButtonType.Secondary}
                                    buttonSize={ButtonSize.ExtraSmall}>
                                Close
                            </Button>
                        </div>
                        <Divider />
                    </div>
                } />
                } allowedRoles={EG.partnerAllowedRoles} />,
                children: [
                    {
                        path: 'create',
                        async lazy() {
                            let {CreateNewClient} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<CreateNewClient />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    }
                ]
            },
            {
                path: 'profile',
                async lazy() {
                    let {ProfilePartner} = await import("./partnerPortalRouteComponents");
                    return {
                        Component: () => <PrivateRoute component={<SideBar children={<ProfilePartner />}/>} allowedRoles={EG.partnerAllowedRoles} />,
                    }
                },
                children: [
                    {
                        index: true,
                        async lazy() {
                            let {PartnerProfile} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerProfile />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'my-subscription',
                        async lazy() {
                            let {PartnerSubscription} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerSubscription />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'account-password',
                        async lazy() {
                            let {PartnerAccountPassword} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerAccountPassword />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'bar-licenses',
                        async lazy() {
                            let {PartnerBarLicenses} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerBarLicenses />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'signed-agreements',
                        async lazy() {
                            let {PartnerSignedAgreements} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerSignedAgreements />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'compensation',
                        async lazy() {
                            let {PartnerCompensation} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerCompensation />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'review-scope',
                        async lazy() {
                            let {PartnerReviewScope} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<PartnerReviewScope />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    },
                    {
                        path: 'account-security',
                        async lazy() {
                            let {AccountSecurity} = await import("./partnerPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AccountSecurity />} allowedRoles={EG.partnerAllowedRoles} />,
                            }
                        },
                    }
                ]
            },
        ]
    },
    {
        path: '/app/sys',
        async lazy() {
            let {AdminApp} = await import("./adminPortalRouteComponents");
            return {
                Component: () => <PrivateRoute component={<AdminApp />} allowedRoles={EG.adminAllowedRoles} />,
            }
        },
        loader: loader('admin'),
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        index: true,
                        async lazy() {
                            let {AdminHome} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AdminHome />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'admin/users',
                        async lazy() {
                            let {AdminUsers} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AdminUsers />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'admin/estateplanmanage',
                        async lazy() {
                            let {AdminEstatePlanManage} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AdminEstatePlanManage />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'admin/configurations',
                        async lazy() {
                            let {AdminConfigurations} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AdminConfigurations />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'attorney',
                        async lazy() {
                            let {AttorneyManage} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AttorneyManage />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'attorney/history',
                        async lazy() {
                            let {AttorneyHistory} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AttorneyHistory />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'attorney/compensation',
                        async lazy() {
                            let {AttorneyCompensation} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AttorneyCompensation />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'attorney/agreement',
                        async lazy() {
                            let {AttorneyAgreement} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<AttorneyAgreement />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'whitelabels',
                        async lazy() {
                            let {WhiteLabelManage} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<WhiteLabelManage />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'whitelabels/products',
                        async lazy() {
                            let {WhiteLabelProducts} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<WhiteLabelProducts />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'reports',
                        async lazy() {
                            let {ReportsProgress} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<ReportsProgress />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'reports/aging',
                        async lazy() {
                            let {ReportsAging} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<ReportsAging />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'reports/shipping',
                        async lazy() {
                            let {ReportsShipping} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<ReportsShipping />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'reports/billing',
                        async lazy() {
                            let {ReportsBilling} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<ReportsBilling />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                    {
                        path: 'reports/deeds',
                        async lazy() {
                            let {ReportsDeeds} = await import("./adminPortalRouteComponents");
                            return {
                                Component: () => <PrivateRoute component={<ReportsDeeds />} allowedRoles={EG.adminAllowedRoles} />,
                            }
                        }
                    },
                ]
            }
        ]
    }
]);

export default router;