// ModalUtils.ts

import {ModalOptions, AlertOptions} from "../components/global/modals/AlertProvider";

let showModalFunction: (options: ModalOptions) => void;
let showAlertFunction: (options: AlertOptions) => void;
let hideModalFunction: (options?: ModalOptions) => void;
let hideAlertFunction: (options?: AlertOptions) => void;

export const setShowModalFunction = (func: (options: ModalOptions) => void) => {
    showModalFunction = func;
};

export const setShowAlertFunction = (func: (options: AlertOptions) => void) => {
    showAlertFunction = func;
};

export const setHideModalFunction = (func: () => void) => {
    hideModalFunction = func;
};

export const setHideAlertFunction = (func: () => void) => {
    hideAlertFunction = func;
};

export const showModal = (options: ModalOptions) => {
    if (showModalFunction) {
        showModalFunction(options);
    } else {
        console.error('showModalFunction is not set');
    }
};

export const showAlert = (options: AlertOptions) => {
    if (showAlertFunction) {
        showAlertFunction(options);
    } else {
        console.error('showAlertFunction is not set');
    }
};

export const showErrorAlert = (infoText:string, description:string) => {
    showAlert({
        color: "red",
        infoText: infoText,
        description: description,
        alertIcon: true,
        xIcon: true,
        onXClick: () => hideAlert(),
        autoHide: true,
    });
}

export const hideModal = () => {
    if (hideModalFunction) {
        hideModalFunction();
    } else {
        console.error('hideModalFunction is not set');
    }
};

export const hideAlert = () => {
    if (hideAlertFunction) {
        hideAlertFunction();
    } else {
        console.error('hideAlertFunction is not set');
    }
};