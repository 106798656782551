import React, { ReactElement } from 'react';
import { classNames } from "../../helpers/Functions";

interface Props {
    color?: "dark-grey"|"grey"|"light-grey"|"blue";
    orientation?: "horizontal"|"vertical";
    margins?: "compact"|"regular"|"loose";
    cssClasses?: string;
}

/**
 * This is a divider component.
 *
 * @param           {string} color optional: specifies the divider color. defaults to grey.
 * @param           {string} orientation optional: specifies the divider orientation. defaults to horizontal.
 * @param           {string} margins optional: specifies the divider margins. defaults to regular (40px)
 * @param           {string} cssClasses optional: specifies additional styling to overwrite anything
 *
 * @Design          https://www.figma.com/file/maN5OPivlYlKrPYhS8yLu2/Design-System?node-id=741%3A9032&t=3lTbd19mVR0Rsv2S-0
 */
const Divider: React.FC<Props> = ({ color='grey', orientation='horizontal', margins='regular', cssClasses }): ReactElement => {
    const classes = {
        color: {
            "dark-grey": 'border-neutralPrimary',
            "grey": 'border-neutralPrimary-200',
            "light-grey": 'border-neutralPrimary-300',
            "blue": 'border-primary',
        },
        orientation: {
            "horizontal": 'horizontal border-t',
            "vertical": 'vertical border-l'
        },
        margins: {
            "horizontal": {
                "compact": "my-2",
                "regular": "my-5",
                "loose": "my-9"
            },
            "vertical": {
                "compact": "mx-2",
                "regular": "mx-5",
                "loose": "mx-9"
            }
        }
    }

    return (
        <div
            className={classNames(classes.color[color], classes.orientation[orientation], classes.margins[orientation][margins], cssClasses ?? "")}
        />
    );
}

Divider.defaultProps = {
    color: 'grey',
    orientation: 'horizontal',
    margins: 'regular'
};

export default Divider;
