import Api from './Api';


export async function getEstatePlanSummary (estatePlanId: string) {
    const url = `plan/${estatePlanId}/overview`;

    const response = await Api.get(url);

    const EstatePlanSummary = await response.json();

    EstatePlanSummary.getPrimaryPerson = () => {
        return EstatePlanSummary.people?.find((person: any) => person.id === EstatePlanSummary.ownerIdPrimary)
    }

    EstatePlanSummary.getSecondaryPerson = () => {
        return EstatePlanSummary.people?.find((person: any) => person.id === EstatePlanSummary.ownerIdSecondary)
    }

    EstatePlanSummary.getPersonByUserId = (userId:any) => {
        return EstatePlanSummary.people?.find((person: any) => person.userId === userId);
    }

    EstatePlanSummary.primaryOwner = EstatePlanSummary.getPrimaryPerson();
    EstatePlanSummary.secondaryOwner = EstatePlanSummary.getSecondaryPerson();

    return EstatePlanSummary;

}
