import React, {createContext, useContext, useState} from "react";

interface EstatePlanSummaryProviderProps {
    children: React.ReactNode
}

export const EstatePlanSummaryContext = createContext<any>(null); //TODO: don't use any. context is using some complex logic here and is throwing errors when giving a specific type.

export const EstatePlanSummaryProvider: React.FC<EstatePlanSummaryProviderProps> = ({children }) => {
    const [EstatePlanSummary, setEstatePlanSummary] = useState<any | null>(null);

    return (
        <EstatePlanSummaryContext.Provider value={[EstatePlanSummary, setEstatePlanSummary]}>
            {children}
        </EstatePlanSummaryContext.Provider>
    );
};
