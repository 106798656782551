import {Outlet} from "react-router-dom";
import React from "react";
import Logo from "../../global/Logo";

export default function SideBar (props: {children: JSX.Element, mainComponent?: JSX.Element}) {

    return (
        <div className={'inset-0 absolute flex flex-col min-w-[1024px]'}>
            <div className={'flex-1'}>
                <div className={'grid grid-cols-12 min-w-[853px]'}>

                    {/* SIDEBAR */}
                    <div className={'col-span-2 flex flex-col border-r border-neutralPrimary-200 items-center min-w-[171px]'}>
                        <div className={'sticky top-0 w-full'}>
                            <div className="h-[100vh] overflow-y-auto overscroll-contain ">
                                <div>
                                    <div className={"text-primary-200 justify-center flex"}>
                                        <Logo type={'noName'} additionalClasses={'w-[29.5px] pt-3 flex justify-center -mb-2'}/>
                                    </div>
                                </div>

                                {props.children}
                            </div>
                        </div>


                    </div>

                    {/* MAIN CONTENT */}
                    <div className="col-span-10 bg-neutralPrimary-100">
                        <div className="grid grid-cols-10">
                            <div className="col-span-1 bg-neutralPrimary-100"></div>
                            <div className="col-span-7 col-start-2 pt-12 col-end-8">
                                <div className="max-w-[2613px]">
                                    {props.mainComponent ? props.mainComponent : <Outlet/>}
                                </div>
                            </div>
                            <div className="col-span-2 bg-neutralPrimary-100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}