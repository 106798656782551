import Button, {ButtonType, ButtonSize} from "../buttons/Button";
import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useState, useEffect, FC} from "react";
import {XMarkIcon} from '../icons/Icons'

export enum ModalSize {
    Small = 'sm',
    Medium = 'md',
    Large = 'lg',
}

interface ModalProps {
    show: boolean;
    title?: string | JSX.Element;
    subtitle?: string | JSX.Element;
    text?: string | JSX.Element;
    onClose: () => void;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick?: () => void;
    checkBoxClick?: (val: boolean) => void;
    primaryButtonText: string;
    secondaryButtonText?: string;
    size: ModalSize;
    checkboxText?: string;
    setIsOpen: (isOpen: boolean) => void;
    warningIcon?: boolean; // mutually exclusive with successIcon
    successIcon?: boolean; // mutually exclusive with warningIcon
    disableOutsideClick?: boolean; // If the user clicks outside the modal it won't close it if this is set to true
    showXClose?: boolean;
}

export const Modal: FC<ModalProps> = ({
                                                show,
                                                title,
                                                subtitle,
                                                text,
                                                onClose,
                                                onPrimaryButtonClick,
                                                onSecondaryButtonClick,
                                                primaryButtonText,
                                                secondaryButtonText,
                                                size = ModalSize.Medium,
                                                checkboxText,
                                                setIsOpen,
                                                warningIcon=false,
                                                successIcon=false,
                                                checkBoxClick,
                                                disableOutsideClick = false,
                                                showXClose = true,
                                            }) => {
    const [isOpen, setIsOpenState] = useState(show);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsOpenState(show);
    }, [show]);

    // useEffect(() => {
    //     console.log('isOpen has changed:', isOpen);
    // }, [isOpen]);

    const handleXClick = () => {
        setIsOpen(false);
        onClose();
    };

    const handlePrimaryButtonClick = () => {
        onPrimaryButtonClick();
    };

    const handleSecondaryButtonClick = () => {
        if (onSecondaryButtonClick)
            onSecondaryButtonClick();
    };

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
        if (checkBoxClick)
            checkBoxClick(!isChecked);
    };

    const getSizeClassName = (size: ModalSize): string => {
        switch (size) {
            case ModalSize.Small:
                return 'w-1/5'; // Small modal, 25% width
            case ModalSize.Medium:
                return 'w-1/4'; // Medium modal, 50% width
            case ModalSize.Large:
                return 'w-1/3'; // Large modal, 75% width
            default:
                return 'w-1/4'; // Default to medium
        }
    };

    const getFontSizeClassName = (size: ModalSize): string => {
        switch (size) {
            case ModalSize.Small:
                return 'text-sm';
            case ModalSize.Medium:
                return 'text-base';
            case ModalSize.Large:
                return 'text-lg';
            default:
                return 'text-base';
        }
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className={`fixed z-50 inset-0 overflow-y-auto ${
                    isOpen ? "block" : "hidden"
                }`}
                onClose={disableOutsideClick ? () => {} : handleXClick}
            >
                <div className={`flex items-center justify-center min-h-screen`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75"></Dialog.Overlay>
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={`relative bg-white rounded-lg shadow-lg p-8 mx-4 ${getSizeClassName(size)}`}>
                            {
                                warningIcon && !successIcon &&
                                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="64" viewBox="0 0 58 64" fill="none">
                                            <circle cx="29" cy="29" r="29" fill="white" />
                                            <path fillRule={"evenodd"}
                                                  clipRule={"evenodd"}
                                                  d={"M28.9986 58.6654C41.9894 58.6654 52.5206 46.7263 52.5206 31.9987C52.5206 17.2711 41.9894 5.33203 28.9986 5.33203C16.0077 5.33203 5.47656 17.2711 5.47656 31.9987C5.47656 46.7263 16.0077 58.6654 28.9986 58.6654ZM28.9986 14.332C30.6554 14.332 31.9986 15.6752 31.9986 17.332V35.9987C31.9986 37.6556 30.6554 38.9987 28.9986 38.9987C27.3417 38.9987 25.9986 37.6556 25.9986 35.9987V17.332C25.9986 15.6752 27.3417 14.332 28.9986 14.332ZM32.5269 47.732C32.5269 49.9412 30.9472 51.732 28.9986 51.732C27.0499 51.732 25.4703 49.9412 25.4703 47.732C25.4703 45.5229 27.0499 43.732 28.9986 43.732C30.9472 43.732 32.5269 45.5229 32.5269 47.732Z"}
                                                  fill={"#DC0916"}
                                            />
                                        </svg>
                                    </div>
                            }
                            {
                                successIcon && !warningIcon &&
                                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/3 flex items-center justify-center">
                                        <svg width="58" height="64" viewBox="0 0 58 64" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="29" cy="29" r="29" fill="white"/>
                                            <path
                                                fillRule={"evenodd"}
                                                clipRule={"evenodd"}
                                                d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.8 6.6L9.5 14.6C9.3 14.9 9.1 15 8.7 15H8.6C8.3 15 8.1 14.9 7.9 14.7L5.2 11.8C4.8 11.4 4.9 10.8 5.3 10.4C5.7 10 6.3 10 6.7 10.5L8.5 12.5L13.1 5.6C13.4 5.1 14 5 14.5 5.3C15 5.5 15.1 6.1 14.8 6.6Z"
                                                fill={"#2F6912"}
                                                transform={"scale(2.4) translate(2.2, 2.2)"}
                                            />
                                        </svg>
                                    </div>
                            }
                            {showXClose &&
                                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={handleXClick}
                                        tabIndex={-1}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                            }
                            <div className="mb-4">
                                <h2 className={`text-lg text-left text-primary text-[22px] font-semibold ${getFontSizeClassName(size)}`}>{title}</h2>
                            </div>
                            {title ? (
                                <div className="border-t-2 border-gray-200 my-4"/>
                            ) : null}
                            {subtitle && (
                                <div className="mb-4 text-center">
                                    <h3 className={`text-base font-base text-[26px] text-primary ${getFontSizeClassName(size)}`}>{subtitle}</h3>
                                </div>
                            )}
                            {text && (
                                <div className="mb-4 text-center">
                                    <p className={`text-sm text-neutralSecondary ${getFontSizeClassName(size)}`}>{text}</p>
                                </div>
                            )}
                            <div className="flex justify-center space-x-4">
                                {secondaryButtonText ? (
                                    <Button
                                        buttonType={ButtonType.Secondary}
                                        onClick={handleSecondaryButtonClick}
                                        buttonSize={size === ModalSize.Large ? ButtonSize.Large : ButtonSize.Small}
                                    >
                                        {secondaryButtonText}
                                    </Button>
                                ) : null}
                                <Button
                                    buttonType={ButtonType.Primary}
                                    onClick={handlePrimaryButtonClick}
                                    buttonSize={size === ModalSize.Large ? ButtonSize.Large : ButtonSize.Small}
                                >
                                    {primaryButtonText}
                                </Button>
                            </div>
                            {checkboxText ? (
                                <div className="flex justify-center mt-6">
                                    <div className="flex items-center">
                                        <input
                                            id="checkbox"
                                            name="checkbox"
                                            type="checkbox"
                                            className="h-4 w-4 text-primary focus:ring-indigo-500 border-primary rounded"
                                            checked={isChecked}
                                            onChange={handleCheckboxClick}
                                        />
                                        <label htmlFor="checkbox" className="ml-2 block text-sm text-neutralSecondary">
                                            {checkboxText}
                                        </label>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Modal;