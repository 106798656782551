import {useState, useCallback, useEffect} from 'react';

export function useCustomQueue() {
    const [queue, setQueue] = useState<any[]>([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const processNextRequest = useCallback(async () => {
        if (queue.length === 0 || isProcessing) return;

        setIsProcessing(true);
        const { myFunc, onSuccess, onError } = queue[0];

        try {
            const result = await myFunc();
            onSuccess(result);
        } catch (error) {
            onError(error);
        } finally {
            setQueue((prevQueue) => prevQueue.slice(1)); // Remove processed request from the queue
            setIsProcessing(false);
        }
    }, [queue, isProcessing]);

    const addRequest = (myFunc:() => void, onSuccess:(response:any) => void, onError:(error:any) => void) => {
        setQueue((prevQueue:any[]) => [...prevQueue, { myFunc, onSuccess, onError }]);
    };

    // Call processNextRequest whenever queue changes
    useEffect(() => {
        void processNextRequest();
    }, [queue, processNextRequest]);

    return { addRequest, queueLength: queue.length };
}
