import React, { createContext, useState } from "react";
import {useCustomQueue} from "../client/CustomHooks/useCustomQueue";

interface userProviderProps {
    children: React.ReactNode
}

export const UtilsContext = createContext<any>(null);

export const UtilsProvider: React.FC<userProviderProps> = ({children }) => {
    const apiPostQueue = useCustomQueue();

    return (
        <UtilsContext.Provider value={{apiPostQueue: apiPostQueue}}>
            {children}
        </UtilsContext.Provider>
    );
};
