import React, {createContext, useContext, useEffect, useState} from "react";
import EG from "../../helpers/EG";
import {EstatePlanSummaryContext} from "../global/EstatePlanSummaryProvider";
import {LegalFileContext} from "../global/LegalFileProvider";
import Definitions from '../../definitions'

interface userProviderProps {
    children: React.ReactNode
}

export const NotifyPackageNotSignedContext = createContext<any>(null); //TODO: don't use any. context is using some complex logic here and is throwing errors when giving a specific type.

export const NotifyPackageNotSignedProvider: React.FC<userProviderProps> = ({children }) => {
    const [legalFile] = useContext(LegalFileContext);
    const [estatePlanSummary] = useContext(EstatePlanSummaryContext);
    const [showNotifyPackageNotSigned, setShowNotifyPackageNotSigned] = useState<boolean>(false);

    useEffect(() => {
        if (legalFile === null) {
            return
        } else {
            const mainDocument = EG.shouldShowSigningDate(estatePlanSummary);
            const packageDef = Definitions.packages.find((p) => p.alias === mainDocument?.alias);
            const needsTrustDateLocal = !!(mainDocument && !legalFile.getLegalFile(packageDef?.signingDateField?.legalFile).get(packageDef?.signingDateField?.field.name))
            if (needsTrustDateLocal) setShowNotifyPackageNotSigned(needsTrustDateLocal);
        }
    }, []);


    return (
        <NotifyPackageNotSignedContext.Provider value={[showNotifyPackageNotSigned, setShowNotifyPackageNotSigned]}>
            {children}
        </NotifyPackageNotSignedContext.Provider>
    );
};