import {useState, useContext, useEffect} from "react";
import EG from "../../../helpers/EG";
import security from "../../../security";
import {EstatePlanSummaryContext} from "../../global/EstatePlanSummaryProvider";
import {UserContext} from "../../global/UserProvider";
import Definitions from '../../../definitions';


interface IHasPackageAccess {
    trustWill: boolean,
    trustWillSecondary: boolean,
    assets: boolean,
    assetsPrimary: boolean,
    assetsSecondary: boolean,
    deeds: boolean,
    fpoaPrimary: boolean,
    hpoaPrimary: boolean,
    fpoaSecondary: boolean,
    hpoaSecondary: boolean,
}

const hasAdvisorPackageWriteAccess = (user: any, estatePlanSummary: any, alias: string, ownerId: number) => {
    const roles = user?.systemPrivs?.roles;
    if (security.isAdvisor(roles)) {
        let whiteLabelId = null; // do we need this?
        let packageAccess = security.hasPackageAccess(whiteLabelId, user, estatePlanSummary, alias, ownerId);
        return packageAccess == Definitions.accessTypes.modify || packageAccess == true; // write access OR full access
    }
    return false;
}

export const useDetermineAccess = (): any => {
    const [estatePlanSummary] = useContext(EstatePlanSummaryContext);
    const [user] = useContext(UserContext);
    const [isAdmin, setIsAdmin] = useState<boolean|undefined>(undefined);
    const [isSysAdmin, setIsSysAdmin] = useState<boolean|undefined>(undefined);
    const [isAdvisor, setIsAdvisor] = useState<boolean|undefined>(undefined);
    const [isStakeholder, setIsStakeholder] = useState<boolean|undefined>(undefined);
    const [hasPackageAccess, setHasPackageAccess] = useState<IHasPackageAccess>({
        trustWill: false,
        trustWillSecondary: false,
        assets: false,
        assetsPrimary: false,
        assetsSecondary: false,
        deeds: false,
        fpoaPrimary: false,
        hpoaPrimary: false,
        fpoaSecondary: false,
        hpoaSecondary: false,
    });

    useEffect(() => {
        if (user) {
            const isAdminLocal = security.isAdmin(user.systemPrivs.roles);
            setIsAdmin(isAdminLocal);

            const isSysAdminLocal = security.isSysAdmin(user.systemPrivs.roles);
            setIsSysAdmin(isSysAdminLocal);
        }
    }, [user]);

    useEffect(() => {
        if (estatePlanSummary) {
            const isAdvisorLocal = hasAdvisorPackageWriteAccess(user, estatePlanSummary, estatePlanSummary.estatePlanType, estatePlanSummary.ownerIdPrimary);
            setIsAdvisor(isAdvisorLocal);

            const hasMoreThanStakeholderPermissions = EG.hasOwnerAccess(user) || isAdvisorLocal;
            setIsStakeholder(!hasMoreThanStakeholderPermissions);

            const hasPackageAccessLocal: IHasPackageAccess = {
                trustWill: false,
                trustWillSecondary: false,
                assets: false,
                assetsPrimary: false,
                assetsSecondary: false,
                deeds: false,
                fpoaPrimary: false,
                hpoaPrimary: false,
                fpoaSecondary: false,
                hpoaSecondary: false,
            };

            let trustType = estatePlanSummary.estatePlanType; // the estate plan type and the death package type are the same
            let assetType = trustType == Definitions.estatePlanTypes.MarriedSeparateTrust ? Definitions.packageList.AssetSchedulesSeparate : Definitions.packageList.AssetSchedules;
            let ownerIdPrimary = estatePlanSummary.ownerIdPrimary;
            let ownerIdSecondary = estatePlanSummary.ownerIdSecondary;

            if ([Definitions.estatePlanTypes.MarriedJointTrust, Definitions.estatePlanTypes.MarriedJointTrustAB].includes(trustType)) {
                hasPackageAccessLocal.trustWill = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, trustType, null);
            } else {
                hasPackageAccessLocal.trustWill = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, trustType, ownerIdPrimary);
            }
            hasPackageAccessLocal.trustWillSecondary = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, trustType, ownerIdSecondary);
            hasPackageAccessLocal.assets = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, null, assetType, null);
            hasPackageAccessLocal.assetsPrimary = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, assetType, ownerIdPrimary);
            hasPackageAccessLocal.assetsSecondary = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, assetType, ownerIdSecondary);
            hasPackageAccessLocal.deeds = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, null, assetType, null);
            hasPackageAccessLocal.fpoaPrimary = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, Definitions.packageList.FinancialPOA, ownerIdPrimary);
            hasPackageAccessLocal.hpoaPrimary = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, Definitions.packageList.HealthCarePOA, ownerIdPrimary);
            hasPackageAccessLocal.fpoaSecondary = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, Definitions.packageList.FinancialPOA, ownerIdSecondary);
            hasPackageAccessLocal.hpoaSecondary = security.hasPackageAccess(estatePlanSummary.whiteLabelId, user, estatePlanSummary, Definitions.packageList.HealthCarePOA, ownerIdSecondary);
            setHasPackageAccess(hasPackageAccessLocal);
        }
    }, [estatePlanSummary]);

    const hasAnyAssetsAccess = () => {
        let hasAnyAssetsAccessLocal: boolean = false;
        if (hasPackageAccess.assets || hasPackageAccess.assetsPrimary || hasPackageAccess.assetsSecondary) {
            hasAnyAssetsAccessLocal = true;
        }
        return hasAnyAssetsAccessLocal;
    }

    // NOTE: isAdmin, isSysAdmin are the only flags that gets passed in the partner portal, this is because you aren't accessing a specific Estate Plan in that portal
    return {isAdmin, isSysAdmin, isAdvisor, isStakeholder, hasPackageAccess, hasAnyAssetsAccess: hasAnyAssetsAccess()};
}
