import {EGPartnerBase} from "./EGPartnerBase";

export class LifeSitePartner extends EGPartnerBase {
    showUsernamePassword(): boolean {
        return false;
    }

    showOAuthLogin(): any { // NOTE: In the EGPartnerBase class this is actually returning a boolean.
        return "SIGN IN WITH LIFESITE";
    }
}


