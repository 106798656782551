import React, { createContext, useContext, useState } from "react";

interface userProviderProps {
    children: React.ReactNode
}

export const UserContext = createContext<any>(null); //TODO: don't use any. context is using some complex logic here and is throwing errors when giving a specific type.

export const UserProvider: React.FC<userProviderProps> = ({children }) => {
    const [user, setUser] = useState<any>(null);

    return (
        <UserContext.Provider value={[user, setUser]}>
            {children}
        </UserContext.Provider>
    );
};
