import React, {useEffect} from "react";
import {UserContext} from "./components/global/UserProvider";

export default function AHAWidget() {
    const [user] = React.useContext(UserContext);

    useEffect(() => {
        if (user) {
            (function(w, d, s, o, f, js, fjs) {
                // @ts-ignore
                w['aha-widget'] = o;w[o] = w[o] || function() {(w[o].q = w[o].q || []).push(arguments);};
                // @ts-ignore
                js = d.createElement(s);fjs = d.getElementsByTagName(s)[0];js.id = o;js.src = f;js.async = 1;fjs.parentNode.insertBefore(js, fjs);
            })(window,document,'script','aha','https://cdn.aha.io/assets/feedback/feedback.js');

            aha('initialize', {
                account: 'estateguru',
                applicationId: '7412877956442403664',
                user: {
                    id: user.user?.id,
                    name: user.user?.firstName,
                    email: user.user?.email1,
                },
            });
        }
    }, [user]);

    return (
        <>
        </>
    )
}