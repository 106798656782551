import {MutableRefObject, useEffect, useState} from "react";

export default function useObserveSize(ref: MutableRefObject<HTMLElement | null>) {
    const [size, setSize] = useState<{ width: number, height: number} | null>(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (!Array.isArray(entries) || entries.length === 0) {
                return;
            }

            const width = entries[0].borderBoxSize[0].inlineSize;
            const height = entries[0].borderBoxSize[0].blockSize;
            setSize({width, height});
        })

        if (ref.current) {
            resizeObserver.observe(ref.current, {box: 'border-box'});
        }

        return () => {
            if (ref.current) {
                resizeObserver.unobserve(ref.current);
            }
            resizeObserver.disconnect()
        }
    }, [ref.current]);

    return size;
}