import {useContext, useEffect, useState} from "react";
import {UserContext} from "../UserProvider";
import EG from "../../../helpers/EG";
import {useLoaderData, useLocation, useNavigate} from "react-router-dom";
import {LegalFileContext} from "../LegalFileProvider";
import {EstatePlanSummaryContext} from "../EstatePlanSummaryProvider";
import {useDetermineAccess} from "../../client/CustomHooks/useDetermineAccess";

interface Props {
    component: JSX.Element;
    allowedRoles: string | string[];
    preventStakeholderAccess?: boolean;
}
export default function PrivateRoute(props: Props) {
    const [user, setUser] = useContext(UserContext);
    const [legalFile, setLegalFile] = useContext(LegalFileContext);
    const [estatePlanSummary, setEstatePlanSummary] = useContext(EstatePlanSummaryContext);
    const [ isAllowed, setIsAllowed ] = useState(false)
    const [ firstRender, setFirstRender ] = useState(true)
    const {isStakeholder} = useDetermineAccess();
    const navigate = useNavigate();
    const location = useLocation()
    const loaderData: any = useLoaderData();

    const allowStakeholder = () => {
        if (isStakeholder && props.preventStakeholderAccess) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        let userObj = user;
        if (loaderData?.user && firstRender) {
            userObj = loaderData?.user;
            setFirstRender(false);
            setUser(userObj);
        }
        if (userObj && EG.userHasAllowedRole(userObj.systemPrivs.roles, props.allowedRoles) && allowStakeholder() /*&& hasRequiredData()*/) {
            //TODO: this conditional will almost certainly change
            setIsAllowed(true)
        }
        else {
            setIsAllowed(false)
            navigate('/app', {state: {from: location}})
        }
    }, [user]);

    function hasRequiredData() {
        let requiredDataArray: string[] = [];
        // TODO: add more when different required data exists for admin and advisor
        if ( location.pathname.startsWith('/app/client')) {
            requiredDataArray = EG.clientRequiredData;
        }
        let result = true;
        requiredDataArray.forEach( (requiredData) => {
            if ( requiredData === 'estatePlanSummary' && !estatePlanSummary) {
                result = false;
            }
            if ( requiredData === 'legalFile' && !legalFile) {
                result = false;
            }
        })
        return result;
    }

    return(
        <>
            {isAllowed && props.component}
        </>
    )
}