import React, { ReactElement } from "react";
import { classNames } from "../../helpers/Functions";


interface Props {
    size?: "xsm" | "sm" | "md" | "lg" | "default";
    infoText: string | React.ReactNode;
    description?: string;
    children?: React.ReactNode;
    alertIcon?: boolean;
    xIcon?: boolean;
    label?: string;
    color: "blue" | "green" | "red" | "orange";
    onXClick?: () => void;
    onLabelClick?: () => void;
    labelPosition?: 'right' | 'bottom'
    propClassName?: string;
}

const styles = {
    size: {
        xsm: 'min-h-[53px] rounded',
        sm: 'min-h-[60px] rounded-lg',
        md: 'min-h-[73px] rounded',
        lg: 'min-h-[80px] rounded-lg',
        default: 'min-h-[53px] rounded-lg'
    },
    textSize: {
        xsm: 'text-sm',
        sm: 'text-base',
        md: 'text-sm',
        lg: 'text-base',
        default: 'text-base'
    },
    descriptionSize: {
        xsm: 'text-xs',
        sm: 'text-[13px]',
        md: 'text-[13px]',
        lg: 'text-sm',
        default: 'text-sm'
    },
    color: {
        blue: 'bg-information-100 border-2 border-tertiary-100',
        // white: 'bg-neutralSecondary-0 border border-information', // DEPRECATED
        green: 'bg-success-100 border-2 border-success',
        red: 'bg-error-100 border-2 border-error',
        orange: 'bg-warning-100 border-2 border-warning'
    },
    labelColor: {
        blue: 'text-neutralSecondary bg-neutralSecondary-0 border border-neutralSecondary',
        // white: 'text-neutralPrimary-100 bg-primary', // DEPRECATED
        green: 'text-neutralSecondary-0 bg-success-steps',
        red: 'text-neutralSecondary-0 bg-error',
        orange: 'text-neutralSecondary-0 bg-warning'
    }
}

const getXCloseIcon = (onXClick: (() => void) | undefined, color: string) => (
    <div data-testid={"x-icon"} className={"cursor-pointer"} onClick={onXClick}>
        <svg fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className={classNames(
            color === "red" ? "text-error " :
                "text-neutralPrimary ", "w-6"
        )}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
);

const getAlertIcon = (color: string) => (
    <svg data-testid="alert-icon" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className={classNames(
        color === 'blue' ? "text-tertiary-100 " :
            color === 'white' ? "text-information " :
                color === 'red' ? "text-error " :
                    "text-warning", "w-6"
    )}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
    </svg>
);

const getCircleIcon = (color: string) => (
    color === "green" ?
        <svg data-testid="alert-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6">
            <circle cx="12" cy="12" r="10" strokeWidth="2" fill="currentColor" className="text-success-steps"/>
            <path d="M7 13l3 3 7-7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-success-100"/>
        </svg>
        :
        getAlertIcon(color)
);

const Alert: React.FC<Props> = ({ size, infoText, description, children, alertIcon, xIcon, label, color, onXClick, labelPosition, onLabelClick, propClassName }: Props): ReactElement => {
    const labelBubble = <div {...onLabelClick && {onClick: () => onLabelClick()}} className={classNames(onLabelClick ? "cursor-pointer" : "", styles.labelColor[color], "px-4 rounded-full py-1 flex")}><p className={'w-full text-nowrap truncate overflow-hidden text-sm'}>{label}</p></div>

    return (
        <div data-testid={"alertDiv"} className={classNames(styles.size[size || 'default'], styles.color[color || 'blue'], "shadow-md", propClassName || '')}>
            <div className="flex px-3 py-4 gap-x-2 w-full justify-between">
                <div className="flex justify-between w-full">
                    <div className="flex flex-col text-neutralSecondary">
                        <div className={"flex items-center space-x-2"}>
                            <div>{alertIcon && getCircleIcon(color)}</div>
                            <div className={classNames(styles.textSize[size || 'default'], "font-semibold")}>
                                {infoText}
                            </div>
                        </div>
                        <div className={"flex items-center space-x-2"}>
                            <div className={"min-w-[16px]"}></div>
                            <div className={classNames(styles.descriptionSize[size || 'default'], "font-normal")}>
                                {children ? children : (description || '')}
                            </div>
                        </div>
                    </div>
                    <div className={"flex"}>
                        {label && labelPosition === 'right' && <div className="pr-2">{labelBubble}</div>}
                    </div>
                </div>
                <div>
                    {xIcon && <div className="ml-auto">{getXCloseIcon(onXClick, color)}</div>}
                </div>
            </div>
            {label && labelPosition === 'bottom' && <div className="flex px-3 pb-4">{labelBubble}</div>}
        </div>
    );
}

Alert.defaultProps = {
    size: 'default',
    description: '',
    alertIcon: false,
    xIcon: false,
    label: '',
    color: 'blue'
};

export default Alert;